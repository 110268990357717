<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="this.$store.getters.imgUrl"
      accept=".jpg,.jpeg,.png,.gif,.ico"
      :headers="header"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :data="action"
    >
      <img v-if="banner" :src="baseurl + yu + banner" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon" />
      <div class="el-upload__tip waring" slot="tip" v-show="tip">
        {{ this.tip }}
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      default() {
        return {}
      }
    },
    banner: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      // banner: '',
      // img:
      header: {
        Token: sessionStorage.getItem('token')
      }
    }
  },

  methods: {
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        // console.log(res.data.url)
        // this.img = res.data.url
        this.$emit('update:banner', res.data.url)
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.waring {
  color: #8c939d;
  line-height: 24px;
  text-align: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
